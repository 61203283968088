import React from 'react';
import { Tooltip } from './Tooltip';
const SocialIcons = (props) => {

  return (
    <ul
      className={
        "social-icons social-icons-muted social-icons-sm mt-lg-auto ms-auto ms-lg-0 d-flex " +
        props.className
      }
    >
      <li className="social-icons-facebook">
        <Tooltip text="Facebook" placement="top">
          <a
            href="https://www.facebook.com/dream2create/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f" />
          </a>
        </Tooltip>
      </li>
      <li className="social-icons-twitter">
        <Tooltip text="Twitter" placement="top">
          <a
            href="https://twitter.com/daileyproject/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter" />
          </a>
        </Tooltip>
      </li>
      <li className="social-icons-instagram">
        <Tooltip text="Instagram" placement="top">
          <a
            href="https://www.instagram.com/creative_dev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram" />
          </a>
        </Tooltip>
      </li>
      {/* <li className="social-icons-dribbble">
        <Tooltip text="Dribbble" placement="top">
          <a
            href="http://www.dribbble.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-dribbble" />
          </a>
        </Tooltip>
      </li> */}
      <li className="social-icons-github">
        <Tooltip text="Github" placement="top">
          <a
            href="https://github.com/DaileyProject/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github" />
          </a>
        </Tooltip>
      </li>
      <li className="social-icons-linkedin">
          <Tooltip text="Linkedin" placement="top">
            <a
            href="https://www.linkedin.com/in/nicholas-atkinson/"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i className="fab fa-linkedin-in" />
            </a>
          </Tooltip>
        </li>
    </ul>
  );

}

export default SocialIcons;
